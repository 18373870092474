import { useEffect, useRef, useState } from 'react';
import { CSAT_NEXT_DAY_SHORT_TERM_RESERVE_REQUIREMENT_SUFFIX, STR_REQ_MESSAGE, STR_REQ_FOOTER_LINK, DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE } from '../Constants';
import '../styles/NextDayShortTermReserveRequirement.scss';
import moment from 'moment';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { ChartProps } from '../Interfaces/Charts';
import { Download } from '../Utils/Downloader';
import { Button } from 'react-bootstrap';
import VCenteredModal from '../Components/VCenteredModal';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

type CsatNextDayShortTermReserveRequirementRecord = {
    forecastPeakHourEst: Date,
    region: string,
    forecastCreatedTime: Date,
    strReq: number,
    strOverwrite: number
}

let csatNextDayShortTermReserveRequirementRecords: CsatNextDayShortTermReserveRequirementRecord[] = [];

async function parseData(data: any) {
    csatNextDayShortTermReserveRequirementRecords = data as CsatNextDayShortTermReserveRequirementRecord[];
}

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    chart: {
        type: 'bar',
        height: 125,
        style: {
            fontFamily: 'Lato, Arial, Helvetica, sans-serif',
            whiteSpace: "wrap"
        },
    },
    title: {
        text: "", 
        align: "left",
    },
    lang: {
        noData: "No data was received"
    },
    xAxis: {
        visible: false,
        min: 0,
        max: 2,
    },
    yAxis: {
        visible: false,
        min: 0,
        max: 20,
        title: {
            text: undefined
        },
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            stacking: 'normal',
            pointWidth: 30,
            states: {
                hover: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return this.series.name;
                },
                align: 'center',
                verticalAlign: 'left',
                y: 40,
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    color: '#000'
                }
            }
        },
        scatter: {
            states: {
                hover: {
                    enabled: false
                }
            }
        }
    },
    tooltip: {
        enabled: false
    },  
};

export default function Chart(props: ChartProps) {
    const systemWideGaugeRef = useRef<HighchartsReactRefObject>(null);
    const [systemWideGaugeOptions, setSystemWideGaugeOptions] = useState<Highcharts.Options>(initialOptions);
    const northCentralGuageRef = useRef<HighchartsReactRefObject>(null);
    const [northCentralGaugeOptions, setNorthCentralGaugeOptions] = useState<Highcharts.Options>(initialOptions);
    const southGaugeRef = useRef<HighchartsReactRefObject>(null);
    const [southGaugeOptions, setSouthGaugeOptions] = useState<Highcharts.Options>(initialOptions);
    const [buttonClicked] = [props.buttonClicked];
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>Next-Day Short-Term Reserve Requirement</span>;

    NoDataToDisplay(Highcharts);

    function getReadableDate(): string {
        if (csatNextDayShortTermReserveRequirementRecords.length > 0) {
            return  "Operating Day - " + moment(csatNextDayShortTermReserveRequirementRecords[0].forecastPeakHourEst).format('DD-MMM-yyyy');
        }
        return '';
    }

    function getMarkerPositionForRequirement(req: number) {
        switch (req) {
            case 0:
                return 5;
            case 1:
                return 15;
            default:
                return 0;
        }
    }

    useEffect(() => {
        const descriptionBody: React.ReactNode = <><span>            
            This chart is a visual representation of MISO’s short-term reserve (STR) requirement for the next operating day. 
            MISO dynamically sets its Short-Term Reserve (STR) Requirement based on its Net Uncertainty* model.
            The model predicts low, medium or high uncertainty for the next operating day.
            A low or medium prediction sets normal STR requirements. A high uncertainty prediction sets high STR requirements.
            Should MISO operations enter a Capacity Advisory or higher, 
            per EOP-002, the STR requirement automatically moves to “high” in order to preposition the grid for the elevated risk.
            This table is updated daily before the Day-Ahead market closes for the target market day.<br></br><br></br>
            *Net Uncertainty is comprised of load, wind, solar, thermal generation availability, and net scheduled interchange.</span></>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_PUBLIC_API_URL}${CSAT_NEXT_DAY_SHORT_TERM_RESERVE_REQUIREMENT_SUFFIX}`, "NextDayShortTermReserveRequirement", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_PUBLIC_API_URL}${CSAT_NEXT_DAY_SHORT_TERM_RESERVE_REQUIREMENT_SUFFIX}`.replace("json", "csv"), "NextDayShortTermReserveRequirement", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_PUBLIC_API_URL}${CSAT_NEXT_DAY_SHORT_TERM_RESERVE_REQUIREMENT_SUFFIX}`.replace("json", "xml"), "NextDayShortTermReserveRequirement", "xml")}>Download XML</Button>
        </div>;

        switch (buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/resreq', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [buttonClicked]);

    useEffect(() => {
        function getChartOptionsForRecord(region: string): Highcharts.Options {
            let recordForRegion = csatNextDayShortTermReserveRequirementRecords.filter(r => r.region === region)[0];
            if (!recordForRegion) return initialOptions;
            let riskY = getMarkerPositionForRequirement(recordForRegion.strReq);

            if (recordForRegion.strOverwrite > 0) {
                if (region === 'Systemwide') {
                    const sysWideStrReqElement = document.getElementById('sysWideStrReq');
                    if (sysWideStrReqElement) {
                        sysWideStrReqElement.innerText = STR_REQ_MESSAGE;
                    }
                    sysWideStrReqElement?.style.setProperty('display', 'block');
                }
                else if (region === 'North/Central') {
                    const northCentralStrReqElement = document.getElementById('northCentralStrReq');
                    if (northCentralStrReqElement) {
                        northCentralStrReqElement.innerText = STR_REQ_MESSAGE;
                    }
                    northCentralStrReqElement?.style.setProperty('display', 'block');
                }
                else if (region === 'South') {
                    const southStrReqElement = document.getElementById('southStrReq');
                    if (southStrReqElement) {
                        southStrReqElement.innerText = STR_REQ_MESSAGE;
                    }
                    southStrReqElement?.style.setProperty('display', 'block');
            }
        }
        function shouldShowNormalSeriesName(recordForRegion: CsatNextDayShortTermReserveRequirementRecord): boolean {
            return recordForRegion.strReq === 0;
        }

        function shouldShowHighSeriesName(recordForRegion: CsatNextDayShortTermReserveRequirementRecord): boolean {
            return recordForRegion.strReq === 1;
        }
        
    
            return {
                ...initialOptions,
                title: {
                    text: `${recordForRegion.region}`,
                },
                series: [
                    {                    
                        name: shouldShowHighSeriesName(recordForRegion) ? 'High' : '',
                        data: [10],
                        color: '#E7503D',
                    } as Highcharts.SeriesBarOptions, // Use SeriesBarOptions for bar series
                    {
                        name: shouldShowNormalSeriesName(recordForRegion) ? 'Normal' : '',
                        data: [10],
                        color: '#80BC42',
                    } as Highcharts.SeriesBarOptions, // Use SeriesBarOptions for bar series
                    {
                        type: 'scatter',
                        showInLegend: false,
                        data: [
                            {
                                x: 0.5, // Adjust this value to position vertically
                                y: riskY // Adjust this value to position laterally
                            },
                        ],
                        marker: {
                            symbol: 'triangle',
                            radius: 10,
                            fillColor: '#000',
                            lineColor: '#FFF',
                            lineWidth: 2,
                        }
                    }
                                            
                ]
            };
        }
        
        function fetchData(): void {
            if(systemWideGaugeRef.current === null ||
                northCentralGuageRef.current === null ||
                southGaugeRef.current === null
            ) {
                return;
            }

            const systemWideChart = systemWideGaugeRef.current.chart;
            const northCentralChart = northCentralGuageRef.current.chart;
            const southChart = southGaugeRef.current.chart;
            systemWideChart.showLoading();
            northCentralChart.showLoading();
            southChart.showLoading();

            fetch(process.env.REACT_APP_PUBLIC_API_URL + CSAT_NEXT_DAY_SHORT_TERM_RESERVE_REQUIREMENT_SUFFIX)
                .then(response => {
                    return response.json();
                }).then(data => {
                    parseData(data);
                    setSystemWideGaugeOptions(getChartOptionsForRecord('Systemwide'));
                    setNorthCentralGaugeOptions(getChartOptionsForRecord('North/Central'));
                    setSouthGaugeOptions(getChartOptionsForRecord('South'));
                }).catch(() => {});
            
            systemWideChart.hideLoading();
            northCentralChart.hideLoading();
            southChart.hideLoading();
        }

        fetchData();
    }, []);

    return (
        <div className='res-requirement'>
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className='table-header'>
                <span className="date">{getReadableDate()}</span>
            </div>
            <div className="res-requirement-chart">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={systemWideGaugeOptions}
                    ref={systemWideGaugeRef}
                />
                <span id='sysWideStrReq'className='strRequirement'></span>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={northCentralGaugeOptions}
                    ref={northCentralGuageRef}
                />
                <span id='northCentralStrReq' className='strRequirement'></span>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={southGaugeOptions}
                    ref={southGaugeRef}
                />
                <span id='southStrReq'className='strRequirement'></span>
            </div>
            <div className="footer-link">
                <a href={STR_REQ_FOOTER_LINK}>
                    Short-term reserve requirements
                </a>
    
            </div>
        </div>
    );
}
